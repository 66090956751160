import * as React from 'react';
import ReactDOM from 'react-dom';
import '@csstools/normalize.css';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as MLink from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';

const mdTheme = createTheme();


import './index.css';
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from 'react-router-dom';

import App from './App';
import {Home} from './App';
import Index from './App';
import * as serviceWorker from './serviceWorker';

import Boplats from './components/boplats'
import Sthlm from './components/sthlmbostad'
import Arbetsformedlingen from './components/arbetsformedlingen'
import Cooptimus from './components/cooptimus'
import Freerider from './components/freerider'

import logo from './images/favicon.svg'

function MainApp(){
    
    try{
        return   (<BrowserRouter> 
        <ThemeProvider theme={mdTheme}>
            
            <header className="page--header">
                <h1><img src={logo} /> <Link to="/">Antons Förmedling</Link></h1>
                <p>
                    A simple aggregation of useful data from multiple sources
                </p>
                <nav>
                    <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/Boplats">Göteborgs Boplats</Link></li>
                    <li><Link to="/Sthlm">Stockholm Bostad</Link></li>
                    <li><Link to="/Arbetsformedlingen">Arbetsförmedlingen</Link></li>
                    <li><Link to="/Cooptimus">Cooptimus</Link></li>
                    <li><Link to="/Freerider">Freerider</Link></li>
                </ul>
                </nav>
            </header>
            <main>
            
            <Switch>
                <Route exact path='/' >
                    <Home />
                </Route>
                <Route exact path='/Boplats' >
                    <Boplats />
                </Route>
                <Route exact path='/Sthlm' >
                    <Sthlm />
                </Route>
                <Route exact path='/Arbetsformedlingen' >
                    <Arbetsformedlingen />
                </Route>
                <Route exact path='/Cooptimus' >
                    <Cooptimus />
                </Route>
                <Route exact path='/Freerider' >
                    <Freerider />
                </Route>
            </Switch>
            
            <App />
            <footer>
            </footer>
            </main>
            </ThemeProvider>       
            </BrowserRouter>)
    }catch(e){
      return (<strong>500 Server error</strong>)
    }
}

ReactDOM.render(<MainApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
