import React, { useEffect, useState, Component } from "react";

import styles from '../styles/arbetsformedlingen.module.scss';

import { Style } from "react-style-tag";
import $ from "jquery";

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ZingGrid from "zinggrid";
const cheerio = require("cheerio");

export default () => {
  return (
    <>
      <main>
        <a href="https://www.arbetsformedlingen.se"><figure>
          <img
            src="https://arbetsformedlingen.se/webdav/files/logo/logo.svg"
            samesite="None"
            width="50px"
          />
        </figure>
        </a>
        <a href="https://jobtechdev.se">
        <figure>
          <span className="navbar-logo" aria-label="Jobtech logo">
            <svg
              class={styles.navbar__logo}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="210 237 450 120"
            >
              <g>
                <g>
                  <path d="M321.16 240.3v55h209.96v-55H321.16zM362.14 274.97c0 4.78-3.43 7.81-8.41 7.81-4.51.0-7.45-2.47-8.29-6.58l3.67-.76c.52 2.23 1.72 4.03 4.66 4.03 2.87.0 4.51-1.63 4.51-5.1v-16.58h-9.89v-3.31h13.75V274.97zM378.66 282.78c-5.9.0-9.53-4.19-9.53-10.76.0-6.58 3.63-10.76 9.53-10.76s9.53 4.19 9.53 10.76C388.18 278.6 384.56 282.78 378.66 282.78zM404.34 282.78c-2.99.0-5.06-1.4-6.3-3.91h-.2v3.43h-3.71v-29.5h3.71v12.36h.2c1.24-2.51 3.31-3.91 6.3-3.91 4.9.0 7.97 3.91 7.97 10.76C412.32 278.88 409.25 282.78 404.34 282.78zM437.48 257.79h-9.05v24.52h-3.83v-24.52h-9.05v-3.31h21.92V257.79zM459.86 272.94h-14.99v.84c0 3.43 2.27 5.9 6.02 5.9 2.67.0 4.7-1.28 5.82-3.39l2.51 1.95c-1.31 2.63-4.22 4.54-8.37 4.54-6.1.0-9.81-4.19-9.81-10.72.0-6.62 3.87-10.8 9.53-10.8 5.66.0 9.29 4.19 9.29 10.13V272.94zM475.14 282.78c-6.02.0-9.57-4.23-9.57-10.76.0-6.54 3.59-10.76 9.53-10.76 4.07.0 6.58 1.95 7.77 4.78l-2.91 1.6c-.76-2.03-2.39-3.27-4.86-3.27-3.55.0-5.58 2.47-5.58 5.9v3.51c0 3.43 1.99 5.9 5.66 5.9 2.63.0 4.31-1.36 5.34-3.47l2.67 1.75C481.96 280.75 479.33 282.78 475.14 282.78zM506.84 282.31h-3.71v-12.6c0-3.55-1.6-5.3-4.58-5.3-2.43.0-4.82 1.2-4.82 3.79v14.11h-3.71v-29.5h3.71v12.36h.16c.88-2.07 2.51-3.91 5.9-3.91 4.22.0 7.06 2.83 7.06 7.81V282.31z"></path>
                  <path d="M378.66 264.33c-3.39.0-5.58 2.03-5.58 6.18v3.03c0 4.15 2.19 6.18 5.58 6.18s5.58-2.03 5.58-6.18v-3.03C384.24 266.36 382.05 264.33 378.66 264.33z"></path>
                  <path d="M450.61 264.21c-3.35.0-5.74 2.47-5.74 5.86v.32h11.12v-.44C455.99 266.52 453.84 264.21 450.61 264.21z"></path>
                  <path d="M402.83 264.41c-2.67.0-4.98 1.4-4.98 4.03v7.18c0 2.63 2.31 4.02 4.98 4.02 3.51.0 5.54-2.35 5.54-5.9v-3.43C408.37 266.76 406.34 264.41 402.83 264.41z"></path>
                </g>
                <g>
                  <path d="M424.98 321.88c-3.33.0-5.7 2.46-5.7 5.83v.32h11.05v-.44C430.33 324.18 428.19 321.88 424.98 321.88z"></path>
                  <path d="M543.89 321.88c-3.33.0-5.7 2.46-5.7 5.83v.32h11.05v-.44C549.24 324.18 547.1 321.88 543.89 321.88z"></path>
                  <path d="M472.39 322c-3.37.0-5.55 2.02-5.55 6.14v3.01c0 4.12 2.18 6.14 5.55 6.14s5.54-2.02 5.54-6.14v-3.01C477.93 324.02 475.76 322 472.39 322z"></path>
                  <path d="M496.41 322.08c-2.65.0-4.95 1.39-4.95 4v7.13c0 2.62 2.3 4 4.95 4 3.49.0 5.51-2.34 5.51-5.86v-3.41C501.91 324.42 499.89 322.08 496.41 322.08z"></path>
                  <path d="M352.88 315.46h-3.96v21.15h3.96c4.2.0 6.38-3.05 6.38-8.36v-4.44C359.26 318.51 357.08 315.46 352.88 315.46z"></path>
                  <path d="M377.42 321.88c-3.33.0-5.7 2.46-5.7 5.83v.32h11.05v-.44C382.77 324.18 380.63 321.88 377.42 321.88z"></path>
                  <path d="M321.16 299.67v55h303.08v-55H321.16zM353.08 339.87h-7.96v-27.65h7.96c6.85.0 10.22 4.79 10.22 13.83C363.3 335.07 359.93 339.87 353.08 339.87zM386.61 330.56h-14.89v.83c0 3.41 2.26 5.86 5.98 5.86 2.65.0 4.67-1.27 5.78-3.37l2.5 1.94c-1.31 2.61-4.2 4.52-8.32 4.52-6.06.0-9.74-4.16-9.74-10.66.0-6.58 3.84-10.74 9.47-10.74 5.63.0 9.23 4.16 9.23 10.06V330.56zM403.34 339.87h-4.6l-7.33-20.44h3.76l2.89 8.6 2.93 8.75h.2l2.93-8.75 2.85-8.6h3.68L403.34 339.87zM434.17 330.56h-14.9v.83c0 3.41 2.26 5.86 5.98 5.86 2.65.0 4.67-1.27 5.78-3.37l2.5 1.94c-1.31 2.61-4.2 4.52-8.32 4.52-6.06.0-9.74-4.16-9.74-10.66.0-6.58 3.84-10.74 9.47-10.74 5.63.0 9.23 4.16 9.23 10.06V330.56zM457.4 339.87h-17.59v-3.05h6.93V313.6h-6.93v-3.05h10.62v26.26h6.97V339.87zM472.39 340.34c-5.86.0-9.47-4.16-9.47-10.7.0-6.54 3.6-10.7 9.47-10.7 5.86.0 9.47 4.16 9.47 10.7C481.86 336.18 478.25 340.34 472.39 340.34zM497.91 340.34c-2.97.0-5.03-1.39-6.26-3.88h-.2v11.33h-3.68v-28.36h3.68v3.41h.2c1.23-2.49 3.29-3.88 6.26-3.88 4.87.0 7.92 3.88 7.92 10.7C505.84 336.46 502.79 340.34 497.91 340.34zM529.93 339.87h-3.29v-13.75c0-3.17-.63-4.24-2.38-4.24-1.5.0-2.65 1.03-2.65 3.05v14.93h-3.29v-13.75c0-3.17-.63-4.24-2.34-4.24-1.58.0-2.69 1.03-2.69 3.05v14.93h-3.29v-20.44h3.29v2.49h.2c.63-1.62 1.66-2.97 3.88-2.97 2.26.0 3.49 1.31 3.76 3.25h.16c.71-1.86 1.98-3.25 4.4-3.25 3.25.0 4.24 2.34 4.24 6.65V339.87zM553.08 330.56h-14.89v.83c0 3.41 2.26 5.86 5.98 5.86 2.65.0 4.67-1.27 5.78-3.37l2.5 1.94c-1.31 2.61-4.2 4.52-8.32 4.52-6.06.0-9.74-4.16-9.74-10.66.0-6.58 3.84-10.74 9.47-10.74s9.23 4.16 9.23 10.06V330.56zM575.99 339.87h-3.68v-12.52c0-3.53-1.59-5.27-4.56-5.27-2.42.0-4.79 1.19-4.79 3.76v14.03h-3.68v-20.44h3.68v3.41h.16c.87-2.06 2.5-3.88 5.86-3.88 4.2.0 7.01 2.81 7.01 7.76V339.87zM600.29 322.47h-9.07v14.34h9.07v3.05h-7.88c-3.33.0-4.87-2.02-4.87-4.91v-12.48H581v-3.05h4.75c1.43.0 1.98-.52 1.98-1.98v-5.23h3.49v7.21h9.07V322.47z"></path>
                </g>
              </g>
              <g>
                <path
                  className="st0"
                  d="M258.92 290.47v-27.11h.01v-5.69h-.01-5.69-28.81v-7.13h14.56c1.11 2.67 3.75 4.55 6.82 4.55 4.08.0 7.39-3.31 7.39-7.39s-3.31-7.39-7.39-7.39c-3.08.0-5.71 1.88-6.82 4.55h-14.56-5.69v5.69 7.13 5.69h5.69 28.81v27.11c-2.67 1.11-4.55 3.75-4.55 6.82.0 4.08 3.31 7.39 7.39 7.39s7.39-3.31 7.39-7.39C263.47 294.22 261.59 291.58 258.92 290.47z"
                ></path>
                <path
                  className="st0"
                  d="M301.75 244.85h-28.83-5.69-.04v5.69h.04c0 12.87.0 44.97.0 68.77.0 16.73-7.17 22.03-15.37 23.7-1.34-1.89-3.54-3.12-6.03-3.12-4.08.0-7.39 3.31-7.39 7.39s3.31 7.39 7.39 7.39c3.27.0 6.04-2.13 7.02-5.07 12.65-2.44 20.04-9.59 20.07-30.47.04-28.22.01-46.51.0-68.6h28.83v7.13h-14.69-5.69-.02v5.69h.02v77.1c-2.67 1.11-4.55 3.75-4.55 6.83.0 4.08 3.31 7.39 7.39 7.39s7.39-3.31 7.39-7.39c0-3.08-1.88-5.71-4.55-6.82v-77.1h14.69 5.69v-5.69-7.13-5.69H301.75z"
                ></path>
              </g>
            </svg>
          </span>
        </figure>
        </a>

        <Ajax link="/api/arbetsformedlingen" />
      </main>
    </>
  );
};

class Ajax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: undefined,

      noData: {
        text: "Waiting For AJAX request",
        fontSize: 22,
        backgroundImage: "https://i.giphy.com/ONk787QkAmAZq.gif",
      },
    };
  }

  render() {

    return (
      <div className="zg-body">
        <div className="" id="thing"></div>

        <zing-grid
          ref={(grid) => {
            this.grid = grid;
            // "https://zinggrid-arbetsformedlingen-search.glitch.me/beta/?localCall%5BpageSize%5D=50&localCall%5Bwebsite%5D=https%3A%2F%2Fzinggrid-arbetsformedlingen-search.glitch.me%2Fbeta&apiCall%5Bq%5D=a&apiCall%5Bpublished-before%5D=2020-06-04T00%3A00%3A00&apiCall%5Blimit%5D=5&apiCall%5Boffset%5D=0&page=11"
          }}
          theme="android"
          _loading-text="Laddar"
          columns-control-draggable
          pager
          search
          sort
          filter
          compact
          zebra
          context-menu-viewport-stop
          page-size="100"
          page-size-options="5,15,25,50,100"
          _height="400"
          id="af"
          noData={this.state.noData}
          
          _editor="modal"
          loading
          viewport-stop
        >
          <zg-data adapter="rest">
            {/* where to retrieve data from -->*/}
            <zg-param name="recordPath" value="results"></zg-param>
            <zg-param name="searchKey" value="q"></zg-param>
            {/*<!--

        <zg-param name="cursor" value="true"></zg-param>
        <zg-param name="prevIDPath" value="previous_cursor"></zg-param>
        <zg-param name="nextIDPath" value="next_cursor"></zg-param>
        <zg-param name="prevIDKey" value="offset"></zg-param>
        <zg-param name="nextIDKey" value="offset"></zg-param>
        <zg-param name="loadByPage" value="true"></zg-param>
          -->*/}
            {/*<!-- 
        <zg-param name="loadByScroll" value="true"></zg-param>
        <zg-param name="sortBy" value='"webpage_url"'></zg-param>

          -->*/}

            <zg-param name="countPath" value="count"></zg-param>
            <zg-param name="loadByPage" value="true"></zg-param>
            <zg-param name="pageKey" value="page"></zg-param>
            <zg-param name="nextPath" value="next"></zg-param>
            <zg-param name="prevPath" value="previous"></zg-param>
          </zg-data>

          <zg-caption>
            <header></header>

            <div className="caption-pager--container">
              <div>
                <span>Showing</span>
                <zg-text value="startrow"></zg-text>
                <span>-</span>
                <zg-text value="endrow"></zg-text>
                <span>of</span>
                <zg-text value="rowcount"></zg-text>
                <span>items</span>
              </div>
              <div>
                <span>Showing</span>
                <zg-select action="pagesize" options="3, 6, 9"></zg-select>
                <span>items</span>
              </div>
            </div>
          </zg-caption>

          <section>
            <form-group id="query">
              <zg-icon name="fa-smile-wink"></zg-icon>

              <label for="limitGrid">
                Limit:
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="100"
                  data-key="limit"
                  id="limitGrid"
                />
              </label>
              <label for="offsetGrid" className="hidden">
                Offset:
                <input
                  type="range"
                  min="0"
                  max="0"
                  disabled
                  value="0"
                  data-key="offset"
                  id="offsetGrid"
                />
              </label>
            </form-group>
            <form-group id="searching" className="hidden">
              <label for="excludeGrid">
                Exclude:
                <input type="text" id="excludeGrid" />
              </label>
              <label for="searchGrid">
                Find:
                <input type="search" id="searchGrid" />
              </label>
            </form-group>
            <form-group id="filters">
              <dt>
                <label for="filterHeadline">
                  Filter By Headline (Tandläkare):{" "}
                </label>
                <input
                  type="checkbox"
                  id="filterHeadline"
                  data-filter-index="headline"
                  data-filter="tandläkare"
                />
              </dt>
              <dt>
                <label for="filterEmail">Filter By (Has) Email: </label>
                <input
                  type="checkbox"
                  id="filterEmail"
                  data-filter-index="application_details.email"
                  data-filter="@"
                />
              </dt>
              <dt>
                <label for="filterDriversLicenseRequired">
                  Filter By (Has) Drivers License Required:{" "}
                </label>
                <input
                  type="checkbox"
                  id="filterDriversLicenseRequired"
                  data-filter-index="driving_license_required"
                  data-filter="✔️"
                />
              </dt>
              {/*<!--
      <label for="filterByAlien">Filter By Species (Alien): </label>
      <input type="checkbox" id="filterByAlien" data-filter-index="species" data-filter="alien" />
      <br />
      <label for="filterByGender">Filter By Gender (Female): </label>
      <input type="checkbox" id="filterByGender" data-filter-index="gender" data-filter="female" />
    -->
          */}
            </form-group>
          </section>

          <zg-column index="id" type="selector"></zg-column>
          <zg-column
            index="favorite"
            width="100"
            sort="false"
            renderer="renderFavorite"
          ></zg-column>
          <zg-column
            index="logo_url"
            header=" "
            type="image"
            content-width="100px"
            sort="false"
          ></zg-column>

          <zg-column
            header="Annonsrubrik"
            index="headline"
            type-element-tag-name="h2"
          ></zg-column>

          <zg-column
            header="ABF-länk"
            index="webpage_url"
            type="url"
            type-url-icon="outsidearrow"
            filter="disabled"
          ></zg-column>

          <zg-column header="Presentation" index="description.text_formatted" renderer="viewDialog">
          </zg-column>


          <zg-column
            index="application_details.email"
            renderer="renderEmail"

          ></zg-column>
          <zg-column index="application_details.url" renderer="renderUrl"></zg-column>
          
{/*

          <zg-column
            header="Arbetsvillkor"
            index="description.conditions"
          ></zg-column>
          <zg-column index="application_details.information"></zg-column>
          <zg-column index="employer.organization_number"></zg-column>
            <zg-column index="information"></zg-column>
          <zg-column index="label"></zg-column>
          <zg-column index="municipality"></zg-column>
          <zg-column index="label"></zg-column>
          <zg-column index="conditions"></zg-column>
           <zg-column index="region"></zg-column>

          */}
          <zg-column
            index="driving_license_required"
            type="select"
            type-select-options="✔️, ❌"
            renderer="renderBooleanAsCheckmark"
          ></zg-column>
          <zg-column
            index="scope_of_work"
            type="text"
            renderer="renderObjectAsMinMax"
          ></zg-column>


        
          <zg-column index="occupation.label"></zg-column>
          <zg-column index="workplace_address.municipality"></zg-column>
          <zg-column index="employment_type.label"></zg-column>
          <zg-column index="duration.label"></zg-column>
          <zg-column
            index="application_deadline"
            type="date"
            sort-asc="true"
          ></zg-column>
         
          <zg-footer>
            <div>
              <span>Showing</span>
              <zg-text value="currpage"></zg-text>
              <span>-</span>
              <zg-text value="pagecount"></zg-text>
              <span>of</span>
              <zg-text value="pagecount"></zg-text>
              <span>rows</span>
            </div>
            <div>
              <zg-button action="prevpage"></zg-button>
              <zg-text value="currpage"></zg-text>
              <zg-button action="nextpage"></zg-button>
            </div>
          </zg-footer>
          <zg-source><small>Source:</small> <a href="https://arbetsformedlingen.se/platsbanken/">Arbetsförmedlingen</a> <sup><a href="https://jobsearch.api.jobtechdev.se">API Docs</a></sup></zg-source>
        </zing-grid>
      </div>
    );
  }

  renderBooleanAsCheckmark(value, cellRef, domRef) {
    return value ? "✔️" : "❌";
  }
  
  renderNotNull(value, cellRef, domRef) {
    return !value || value == null || value == "null"
      ? '<img src="" className="cross" />'
      : value;
  }

  renderObjectAsMinMax(value, cellRef, domRef) {
    //console.log(`renderObjectAsMinMax:`, value, typeof value)
    if(typeof value != "object"){
      return 'N/A'
    } else {

        let str = "";
        const {min, max} = value;
        if(min === max) str = '100%';
        else {
          str = `${min}% - ${max}%`
        }
        return str; //JSON.stringify(importance, null, 2);
    }
  }

  renderObjectAsString(value, cellRef, domRef) {
    //console.log(`renderObjectAsString:`, value, typeof value)
    if(typeof value != "object"){
      return 'N/A'
    } else {

        let str = "";
        for (const key in value) {
          console.log(key, value[key])
          str += `<dt>${key}</dt><dd>${value[key]}</dd>`;
        }
        return str; //JSON.stringify(importance, null, 2);
    }
  }

  renderEmail(value, cellRef, $cell){
    if(!value){
      return 'N/A'
    }
    return `<a href="mailto:${value}">
    ✉️ ${value}
  </a>`;
   
    /*             <template>
              <a href="mailto:[[record.application_details.email]]">
                ✉️ [[record.application_details.email]]
              </a>
            </template>
            */



  }
  renderFavorite(value, cellRef, $cell){
    return `<input type="checkbox" id="" />`;
  }

 

  renderUrl(value, cellRef, $cell){
    if(!value){
      return 'N/A'
    }
    return `<a href="${value}">🔗</a>`;
  }
  
  renderPresentation(value, cellRef, $cell){
    return `<details>
      <summary>Beskrivning</summary>
      <p>${value}</p>
    </details>`
  }

  componentDidMount() {
    
    ZingGrid.registerMethod(this.renderBooleanAsCheckmark, 'renderBooleanAsCheckmark')

    ZingGrid.registerMethod(this.renderObjectAsString, 'renderObjectAsString')
    ZingGrid.registerMethod(this.renderObjectAsMinMax, 'renderObjectAsMinMax')
    
    
    ZingGrid.registerMethod(this.renderNotNull, 'renderNotNull')
    ZingGrid.registerMethod(this.renderEmail, 'renderEmail')
    ZingGrid.registerMethod(this.renderUrl, 'renderUrl')
    ZingGrid.registerMethod(this.renderPresentation, 'renderPresentation')
    ZingGrid.registerMethod(this.renderFavorite, 'renderFavorite')

    //ZingGrid.registerCellType('mail', { renderer: 'renderEmail' })
    
    const _this = this;
    /*
      fetch(this.props.link)
        .then(res => res.json())
        .then(
          (result) => {
            // purposely timeout so the loading screen displays longer
            setTimeout(() => {
              console.log('Result is', result)


              this.setState({
                json: JSON.stringify(result)
              })
            }, 2000);
          },
          (error) => { console.log(error) }
        )

        */

    // Javascript code to execute after DOM content
    const zgRef = this.grid; // document.querySelector('zing-grid');

    function bindExpandEvent(e) {
      let oDOMRow = e.detail.ZGTarget;
      oDOMRow.classList.toggle("active");
    }

    zgRef.executeOnLoad((result) => {
      zgRef.addEventListener("row:click", bindExpandEvent);
      $("zg-search").attr(
        "title",
        `Du kan använda smarta sökningar: 
           Exclude words from your search
           Put - in front of a word you want to leave out. For example, jaguar speed -car
 
           Search for an exact match
           Put a word or phrase inside quotes. For example, "tallest building".`
      );

      let count = 0;
      zgRef.addEventListener("cell:click", () => {

        result.textContent = "Cell Clicks: " + ++count;
      });
    });

    const swaggerJson = "https://jobsearch.api.jobtechdev.se/swagger.json";
    const endpoint = window.document.location.origin + "" + this.props.link;
    console.log("endpoint:", endpoint);
    //"https://zinggrid-arbetsformedlingen-search.glitch.me/beta"

    var runSearch = () => {};
    var updater = {};

    let locale = "sv-se";
    let generateAPIDate = (date) =>
      date.toLocaleDateString(locale) + "T" + date.toLocaleTimeString(locale);

    const dateObject = {
      second: 1000,
      minute: 60 * 1000,
      hour: 60 * 60 * 1000,
      halfday: 12 * 60 * 60 * 1000,
      day: 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      month: 30 * 7 * 24 * 60 * 60 * 1000,
      year: 365 * 24 * 60 * 60 * 1000,
    };

    const { second, minute, hour, halfday, day, week, month, year } =
      dateObject;
    let igar = generateAPIDate(new Date(new Date() - 24 * 60 * 60 * 1000));
    let idag = generateAPIDate(new Date());

    function Query() {
      updater = {
        localCall: {
          pageSize: zgRef.getPageSize(),
          website: endpoint,
        },
        apiCall: {
          //"q": "a",
          "published-after": igar,
          "published-before": idag,
          limit: zgRef.getPageSize(),
        },
      };

      console.log({ updater });

      function _toQueryString(obj, name) {
        return (
          encodeURIComponent(name) +
          "=" +
          encodeURIComponent(JSON.stringify(obj))
        );
      }

      const updateQuery = (updater) => {
        console.log("Got request to update src:", updater);
        let saved_updater = localStorage.getItem("saved_updater");
        if (saved_updater) {
          saved_updater = JSON.parse(saved_updater);
          if (saved_updater === updater) {
            console.log("Request denied", updater);
          }
        } else {
          localStorage.setItem("saved_updater", JSON.stringify(updater));
        }

        $("#query input").each((i, e) => {
          $(e).val(updater.apiCall[$(e).attr("data-key")]);
        });

        let data_url = endpoint + "?" + _toQueryString(updater, "updater");

        //let data_url = endpoint + `?${$.param(updater)}`
        console.log(
          "Requesting query: ",
          updater,
          "from URL",
          decodeURIComponent(data_url)
        );
        $("zg-data").attr("src", data_url);
        zgRef.setPageSize(100);
        zgRef.setPageIndex(1);
      };

      runSearch = () => {
        $("#query input").each((i, e) => {
          let $t = $(e.target);
          let key = $t.attr("data-key");
          updater.apiCall[key] = $t.value || $t.val();

          updateQuery(updater);
        });
      };

      $("#query input").on("change", (e) => {
        runSearch();
      });

      updateQuery(updater);
    }
    Query();

    /*
           
   $('body').prepend(
     document.querySelector("zing-grid").getPageIndex(),
     document.querySelector("zing-grid").getPageSize()
   )
   */
    let _excludeGrid;

    function Exclude() {
      const excludeGrid = document.getElementById("excludeGrid");

      let reject = (haystack, needle) => {
        if (needle.length == 0) return haystack;
        return haystack.filter(
          (haystack) =>
            !JSON.stringify(haystack)
              .toLowerCase()
              .includes(needle.toLowerCase())
        );
      };

      // debounce exclude
      let excludeTimeoutId = null;

      // function declarations
      _excludeGrid = (e) => {
        // debounce exclude
        clearTimeout(excludeTimeoutId);
        let targetValue = e.target.value.split(",");
        console.log(targetValue);

        // delay 100 milliseconds to debounce user input
        excludeTimeoutId = setTimeout(
          (userInput) => {
            let l = data.length;

            for (n of targetValue) {
              console.log(n, data);
              data = reject(data, n);
            }
            console.log(data);
            requestAnimationFrame(() => zgRef.setData(data));
          },
          300,
          targetValue
        );
      };
    }
    Exclude();

    function Filters() {
      // add event listeners
      excludeGrid.addEventListener("keyup", _excludeGrid);
      excludeGrid.addEventListener("change", _excludeGrid);
      excludeGrid.addEventListener("search", _excludeGrid);

      const filterInputs = document.querySelectorAll("#filters input");

      // function declarations
      function _filterGrid(e) {
        let columnIndex = e.target.getAttribute("data-filter-index");
        let columnFilterValue = e.target.getAttribute("data-filter");
        let filterOn = e.target.checked;

        // if checked off, make sure to turn off filter
        if (!filterOn) columnFilterValue = "";

        // API method to actually filter
        zgRef.filterColumn(columnIndex, columnFilterValue);
      }

      // add event listeners
      [...filterInputs].forEach((filterInput) => {
        filterInput.addEventListener("change", _filterGrid);
      });
    }

    Filters();

    /*
           const extractedData = jsonpath.query(value, '$');
   
           $(function() {
               $('#filter').keyup(function() {
                   console.log(extractedData)
                   try {
                       let filteredData = jsonpath.query(extractedData, $(this).val());
                       $("#content, #errors").empty();
                       $("#content").append("<pre><code>" + JSON.stringify(filteredData, null, 4) + "</code></pre>");
                   } catch (err) {
                       console.info(err);
                       $("#errors").empty();
                       $("#errors").append("<pre><code>" + err + "</code></pre>");
                   }
               });
           });
   
           $( "#resetButton" ).click(function() {
               $("#content, #errors").empty();
               $("#filter").val('');
               $("#content").append("<pre><code>" + JSON.stringify(extractedData, null, 4) + "</code></pre>");
           })
           */
    // });

    $("#app").show();
    $("#showErrors").on("click", function () {
      $("#errors").toggle(this.checked);
    });

    // global event handler for expanding dropdowns
    const expandHandler = function () {
      this.classList.toggle("active");
      this.nextElementSibling.classList.toggle("show");
    };

    // generate randomId for dropdownsreturn Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    const randomId = () => {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    };

    // custom render function for coach info accordion
    function _bindAccordion(name, bio, cellRef, $cell) {
      // grab template contents
      const $input = cellRef.querySelector("input");
      const $label = cellRef.querySelector("label");

      // assign template attributes and custom id's
      // so the label for will trigger the dropdown
      const dropdownID = randomId();
      $input.setAttribute("id", `accordion_${dropdownID}`);
      $label.setAttribute("for", `accordion_${dropdownID}`);
    }
  }
}
