import React, { useEffect, useState, Component } from 'react';

import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from 'react-router-dom';
  import ZingGrid from 'zinggrid';
  const cheerio = require('cheerio')
  
  export default () => {

    return (<>
      <main>
        <img src="https://nya.boplats.se/367/static/logo.png" />
        <Ajax link="/api/boplats?type=fetch"/>
      </main>
    </>
    );
  }
  
  class Ajax extends Component {
    constructor(props) {
      super(props);
      this.state = {
        json: undefined,
  
        noData: {
          text: 'Waiting For AJAX request',
          fontSize: 22,
          backgroundImage: 'https://i.giphy.com/ONk787QkAmAZq.gif'
        }
      }
    }
  
    render() {
      return (
        <div>
          <zing-grid ref={(grid) => { this.grid = grid }} data={this.state.json}
            noData={this.state.noData} 
            editor
            loading>
              <zg-source><small>Source:</small> <a href="https://nya.boplats.se/">nya.boplats.se</a></zg-source>
          </zing-grid>
        </div>
      )
    }
  
    componentDidMount() {
      const _this = this;
      fetch(this.props.link)
        .then(res => res.json())
        .then(
          (result) => {

            const boplats_ads = Object.keys(result).map((idx) => {
              Object.keys(result[idx].boplats_data).map((bidx) => {
                result[idx][bidx] = result[idx].boplats_data[bidx] 
                
              })
              delete result[idx].boplats_data;
              return result[idx]
            })
            

            // purposely timeout so the loading screen displays longer
            setTimeout(() => {
              this.setState({
                json: JSON.stringify(boplats_ads)
              })
            }, 1000);
          },
          (error) => { console.log(error) }
        )
    }
  }
  