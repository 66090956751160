import React, { useEffect, useState, Component } from "react";
import moment from "moment";
import "moment/locale/sv";
moment.locale("sv");

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ZingGrid from "zinggrid";
const cheerio = require("cheerio");

const ExternalLinkIcon = () => {
  return (
    <zg-icon name="outsidearrow">
      <svg id="svg--141269" slot="icon" viewBox="0 0 24 24">
        <path d="M18.04 11.92h-.84a.4.4 0 0 0-.42.42v4.2c0 .57-.2 1.06-.61 1.47-.41.41-.9.62-1.48.62H3.78c-.58 0-1.07-.2-1.49-.62-.4-.4-.61-.9-.61-1.48V5.63c0-.58.2-1.08.61-1.49.42-.41.9-.62 1.49-.62H13a.4.4 0 0 0 .3-.11.4.4 0 0 0 .12-.3v-.84a.4.4 0 0 0-.12-.3.4.4 0 0 0-.3-.12H3.78c-1.04 0-1.93.37-2.67 1.1A3.64 3.64 0 0 0 0 5.62v10.91c0 1.04.37 1.93 1.1 2.67.75.74 1.64 1.1 2.68 1.1h10.9c1.05 0 1.93-.36 2.67-1.1a3.64 3.64 0 0 0 1.11-2.67v-4.2a.4.4 0 0 0-.12-.3.4.4 0 0 0-.3-.11z"></path>
        <path d="M23.75.25a.82.82 0 0 0-.6-.25h-6.83a.82.82 0 0 0-.6.25.82.82 0 0 0-.26.6c0 .24.09.44.25.6l2.35 2.35-8.7 8.7a.42.42 0 0 0 0 .62l1.53 1.52a.42.42 0 0 0 .6 0l8.7-8.7 2.36 2.35c.16.16.36.25.6.25.23 0 .43-.09.6-.25a.82.82 0 0 0 .25-.6V.84a.82.82 0 0 0-.25-.6z"></path>
      </svg>
    </zg-icon>
  );
};

export default () => {
  return (
    <>
      <main>
        <a
          href="https://www.hertzfreerider.se/unauth/list_transport_offer.aspx"
          title="Original source of data"
        >
          <img
            height="100"
            src="https://www.hertzfreerider.se/bilder/logo/loggul.svg"
          />
          *
        </a>

        <Ajax link="/api/hertzfreerider" />
      </main>
    </>
  );
};




class Ajax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: undefined,

      noData: {
        text: "Waiting For AJAX request",
        fontSize: 22,
        backgroundImage: "https://i.giphy.com/ONk787QkAmAZq.gif",
      },
    };
  }

  render() {
    return (
      <div>
        <zing-grid
          ref={(grid) => {
            this.grid = grid;
          }}
          data={this.state.json}
          theme="android"
          _loading-text="Laddar"
          columns-control-draggable
          layout-controls
          pager
          search
          sort
          filter
          compact
          zebra
          context-menu
          context-menu-viewport-stop
          page-size="100"
          page-size-options="5,15,25,50,100"
          _height="400"
          id="grid"
          noData={this.state.noData}
          loading
          layout="row"
        >
          <zg-caption>
            <h1>List of available cars</h1>
            <h2>Check out the cars offered by Hertz FREERIDER.</h2>
          </zg-caption>

          <zg-column
            filter="disabled"
            sort="disabled"
            index="bookId"
            header="Boka"
          >
            <button className="" data-id="[[record.bookId]]">
              Boka
            </button>

          </zg-column>

          <zg-column
            header="Bil"
            index="carType"
            type="select"
          >
            <img
              src="[[record.carImage]]"
              />
            <div>[[record.carType]]</div>
          </zg-column>

          <zg-column index="from.station" type="custom">
            <img src="[[record.fromMapImage]]" className="mapImage" />
            <a href="[[record.fromStationLink]]" target="_blank">
              <div>
                <ExternalLinkIcon /> [[record.fromGeodata.formatted_address]]
              </div>
            </a>
          </zg-column>
          <zg-column index="from.date" type="timeSince"></zg-column>

          <zg-column index="to.station" type="custom">
            <img src="[[record.toMapImage]]" className="mapImage" />
            <a href="[[record.toStationLink]]" target="_blank">
              <div>
                <ExternalLinkIcon /> [[record.toGeodata.formatted_address]]
              </div>
            </a>
          </zg-column>

          <zg-column index="to.date" type="timeSince"></zg-column>

          <zg-column header="Sträcka" index="directions.result.image" type="image">
            <img src="[[record.directions.result.image]]" />

            
            <dl>
              <dt>Körtid</dt>
              <dd>
                [[record.distancematrix.result.first.duration.text]]
              </dd>

              <dt>Körsträcka</dt>
              <dd>
                [[record.distancematrix.result.first.distance.text]]
              </dd>
            </dl>
          </zg-column>
          <zg-source><small>Source:</small> <a href="http://www.hertzfreerider.se/unauth/list_transport_offer.aspx">hertzfreerider.se</a></zg-source>
        </zing-grid>
      </div>
    );
  }

  customRenderer(content, cellRef, $cell) {
    console.log({ content, cellRef, $cell, arguments });
    return;
  }

  componentDidMount() {
    const _this = this;

    let zgRef = this.grid;

    function timeSince(content, cellRef, $cell) {
      if (!content) return "N/A";

      let formatted_date = moment(content, "YYYYMMDD").fromNow();
      return `${content.toUpperCase()}<br /> (${formatted_date})`;
    }
    ZingGrid.registerCellType("timeSince", { renderer: timeSince });

    ZingGrid.registerMethod(this.customRenderer, "customRenderer");

    window.addEventListener("load", function () {
      zgRef.executeOnLoad(() => {
        //zgRef.sortColumn('fromDate')
      });
    });

    fetch(this.props.link)
      .then((res) => res.json())
      .then(
        (result) => {
          const data = result;
          this.setState({
            json: JSON.stringify(data),
          });

          console.log("State is now", data);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
