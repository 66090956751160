import React, { useEffect, useState, Component } from 'react';
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from 'react-router-dom';

import './styles/App.css';
import './styles/main.scss';

import ZingGrid from 'zinggrid';
const cheerio = require('cheerio')
export function Nav() {
  return <>

  </>
}
export function Template({ children }) {
  return <>
    {children}
  </>
}
export function Home() {

  let [date, setDate] = useState();
    
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);


  return (<Template>
    <p>Hello</p>
            <h2>The date according to Go is:</h2>
        <p>{date ? date : 'Loading date...'}</p>
    <h1>Create React App + Go API</h1>
        <h2>
          Deployed with{' '}
          <a
            href="https://vercel.com/docs"
            target="_blank"
            rel="noreferrer noopener"
          >
            Vercel
          </a>
          !
        </h2>
        <p>
          <a
            href="https://github.com/vercel/vercel/tree/main/examples/create-react-app"
            target="_blank"
            rel="noreferrer noopener"
          >
            This project
          </a>{' '}
          was bootstrapped with{' '}
          <a href="https://facebook.github.io/create-react-app/">
            Create React App
          </a>{' '}
          and contains three directories, <code>/public</code> for static assets,{' '}
          <code>/src</code> for components and content, and <code>/api</code>{' '}
          which contains a serverless <a href="https://golang.org/">Go</a>{' '}
          function. See{' '}
          <a href="/api/date">
            <code>api/date</code> for the Date API with Go
          </a>
          .
        </p>
  </Template>)



}
function App() {
  return <></>

}


export default App;