import React, { useEffect, useState, Component } from 'react';

import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from 'react-router-dom';
  import ZingGrid from 'zinggrid';
  const cheerio = require('cheerio')
  
  export default () => {


  
    return (<>
      <main>
        <br />
        <img src="https://bostad.stockholm.se/static/images/bf-logo-6f46bd0e30.svg" />
        <Ajax link="/api/annonser"/>

      </main>
    </>
    );
  }
  

  
  class Ajax extends Component {
    constructor(props) {
      super(props);
      this.state = {
        json: undefined,
  
        noData: {
          text: 'Waiting For AJAX request',
          fontSize: 22,
          backgroundImage: 'https://i.giphy.com/ONk787QkAmAZq.gif'
        }
      }
    }
  
    render() {
      return (
        <div>
          <zing-grid 
          ref={(grid) => { this.grid = grid }} 
          data={this.state.json} 
          theme="android" 
          _loading-text="Laddar" 
          columns-control-draggable 
          layout-controls pager 
          search 
          sort 
          filter
          compact 
          zebra 
          editor-controls 
          context-menu-viewport-stop 
          page-size="100" 
          page-size-options="5,15,25,50,100"
          height="400"
          _noData={this.state.noData} 
          editor
          id="sthlm" 
          loading
          >
            <zg-source><small>Source:</small> <a href="https://bostad.stockholm.se/">bostad.stockholm.se</a></zg-source>
          </zing-grid>
        </div>
      )
    }
  
    componentDidMount() {
      const _this = this;
      const zgRef = this.grid;

      fetch(this.props.link)
        .then(res => res.json())
        .then(
          (result) => {

            console.log({result})
            this.setState({
              json: JSON.stringify(result)
            })
          },
          (error) => { console.log(error) }
        )
    }
  }
  